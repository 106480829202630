<template>
  <div class="side">
    <panav :color='color' :bgColor='bgColor' />
    <img src="~img/38.png" class="bg-img">
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>

<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      color: "#0a9161",
      bgColor: "#add4f7",
      background: "#0a9161",
      noticeColor: "#fff",
    };
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.side {
  height: 100vh;
  .bg-img {
    width: 100%;
    height: 100%;
  }
}
</style>

